// Global Styles
/////////////////////////////////////////////////////////////////

.absofix{
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.textShade{
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}

.fade_hover img {
  opacity: 0.5;
  transition: opacity .25s $base-transition;
}

@screen lg {
  .fade_hover:hover img {
    opacity: 0.1;
  }
}
/*
 * Mapplic - Custom Interactive Map Plugin by @sekler
 * Version 5.0.2
 * https://www.mapplic.com/
 */

.mapplic-element {
	position: relative;
	overflow: hidden;
	font-size: 0;
	height: 420px;
}

.mapplic-element input,
.mapplic-element button,
.mapplic-element a,
.mapplic-element a:active {
	outline: none;
	box-shadow: none;
	text-decoration: none !important;
}

.mapplic-element a:focus {
	outline: none;
}

.mapplic-element > * {
	opacity: 1;
	transition: opacity 0.4s;
}

.mapplic-element.mapplic-loading > * {
	opacity: 0;
}

/* Preloader & Error */
.mapplic-element.mapplic-loading { background: url(/assets/img/mapplic/loader.gif) no-repeat center; }
.mapplic-element.mapplic-error { background: url(/assets/img/mapplic/error-icon.png) no-repeat center; }

/* Map container */
.mapplic-container {
	display: inline-block;
	position: relative;
	width: 70%;
	height: 100%;
}

.mapplic-map {
	transform-origin: 0 0;
}

/* Map layer */
.mapplic-layer img {
	width: 100%;
}

/* IE Fix */
.mapplic-layer {
	width: 100%;
	height: 100%;
	position: absolute;
}

.mapplic-map .mapplic-map-image {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* IE Fix END */

.mapplic-map.mapplic-zoomable .mapplic-map-image {
	cursor: url(/assets/img/mapplic/openhand.cur), default;
}

.mapplic-map.mapplic-zoomable.mapplic-dragging .mapplic-map-image {
	cursor: url(/assets/img/mapplic/closedhand.cur), move;
}

/* Pin types */
.mapplic-pin {
	background-image: url(/assets/img/mapplic/pin.png);
	background-size: 18px 24px;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 0;
	width: 18px;
	height: 24px;
	margin-top: -23px;
	margin-left: -9px;
	position: absolute;
	transform-origin: 50% 100%;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mapplic-pin.hide {
	display: none;
}

/* Marker types */
.mapplic-pin.mapplic-active {
	display: block !important;
}

.mapplic-pin.pin-classic {
	background-color: #f23543;
	background-image: none;
	border-color: #f23543;
	border-radius: 10px;
	line-height: 22px !important;
	width: 20px;
	height: 20px;
	margin-top: -20px;
	margin-left: -10px;
	transform-origin: 50% 100%;
}

.mapplic-pin.pin-classic:before {
	border-style: solid;
	border-width: 9px 8px 0 8px;
	border-color: inherit;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	content: '';
	display: block;
	position: absolute;
	top: 16px;
	left: 2px;
}

.mapplic-pin:before {
	box-sizing: content-box !important;
}

.mapplic-pin.pin-marker {
	background-color: #fdca2a;
	background-image: none;
	border-color: #fdca2a;
	border-radius: 8px;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	transform-origin: 50% 50%;
}

.mapplic-pin.pin-marker:before {
	border-color: inherit;
	border-style: solid;
	border-width: 6px;
	border-radius: 16px;
	content: '';
	display: block;
	opacity: 0.4;
	width: 16px;
	height: 16px;
	position: absolute;
	left: -6px;
	top: -6px;
}

.mapplic-pin.pin-disk {
	background-color: white !important;
	background-image: none;
	border-color: #f19819;
	border-radius: 8px;
	color: #333 !important;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	transform-origin: 50% 50%;
}

.mapplic-pin.pin-disk:before {
	border-color: inherit;
	border-style: solid;
	border-width: 3px;
	border-radius: 16px;
	content: '';
	display: block;
	width: 16px;
	height: 16px;
	position: absolute;
	left: -3px;
	top: -3px;
}

.mapplic-pin.pin-ribbon {
	background-color: #46b450;
	background-image: none;
	border-color: #46b450;
	border-radius: 2px 2px 2px 0;
	height: 16px;
	min-width: 10px;
	width: auto;
	padding: 0 3px;
	margin-left: -8px;
	margin-top: -20px;
	transform-origin: 8px 20px;
}

.mapplic-pin.pin-ribbon:after {
	border-style: solid;
	border-width: 0 8px 4px 0;
	border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
	content: '';
	display: block;
	position: absolute;
	top: 16px;
	left: 0px;
}

.mapplic-pin.pin-ribbon:before {
	border-style: solid;
	border-width: 0 8px 4px 0;
	border-color: inherit;
	border-top-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	content: '';
	display: block;
	position: absolute;
	top: 16px;
	left: 0px;
}

.mapplic-pin.pin-dot {
	background-color: transparent !important;
	background-image: none;
	border-color: #29afa1;
	color: #333 !important;
	height: 16px;
	min-width: 10px;
	width: auto;
	padding: 0 3px;
	margin-left: 4px;
	margin-top: -8px;
	transform-origin: -4px 8px;
}

.mapplic-pin.pin-dot:before {
	border-color: inherit;
	border-style: solid;
	border-width: 3px;
	border-radius: 12px;
	content: '';
	display: block;
	width: 0px;
	height: 0px;
	position: absolute;
	top: 5px;
	left: -7px;
}

/* Image pin */
.mapplic-pin.pin-image {
	background-size: 32px;
	border-radius: 50%;
	box-sizing: border-box;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	border: 2px solid #fff;
	width: 32px;
	height: 32px;
	margin-top: -16px;
	margin-left: -16px;
	transform-origin: 50%;
}

.mapplic-pin.orange { background-image: url(/assets/img/mapplic/pin-orange.png); }
.mapplic-pin.yellow { background-image: url(/assets/img/mapplic/pin-yellow.png); }
.mapplic-pin.green { background-image: url(/assets/img/mapplic/pin-green.png); }
.mapplic-pin.blue { background-image: url(/assets/img/mapplic/pin-blue.png); }
.mapplic-pin.purple { background-image: url(/assets/img/mapplic/pin-purple.png); }

.mapplic-pin.circular {
	background-image: none;
	background-color: #fb7575;
	border-radius: 6px;
	box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
	width: 12px;
	height: 12px;
	margin-left: -6px;
	margin-top: -6px;
	transform-origin: 50% 50%;
}

.mapplic-pin.circle {
	background: none !important;
	border: 2px solid #fb7575;
	width: 8px;
	height: 8px;
	margin-left: -6px;
	margin-top: -6px;
	transform-origin: 50% 50%;
}

.mapplic-pin.transparent {
	background-image: none;
	background-color: #795ecd;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	margin-top: -10px;
	opacity: 0.5 !important;
	transform-origin: 50% 50%;
}

.mapplic-pin.pin-label {
	color: #fff;
	font-size: 10px;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	line-height: 16px;
}

.mapplic-pin.pin-label:visited {
	color: #fff;
}

.mapplic-pin.pin-md {
	border-radius: 50%;
	margin-left: -10px;
	margin-top: -10px;
	line-height: 20px;
	width: 20px;
	height: 20px;
}

.mapplic-pin.pin-lg {
	border-radius: 50%;
	margin-left: -15px;
	margin-top: -15px;
	line-height: 30px;
	width: 30px;
	height: 30px;
}

.mapplic-pin.pin-xl {
	border-radius: 50%;
	margin-left: -20px;
	margin-top: -20px;
	line-height: 40px;
	width: 40px;
	height: 40px;
}

.mapplic-pin.pin-pulse:before {
	content: '';
	border: 2px solid #888;
	border-radius: 30px;
	height: inherit;
	width: inherit;
	top: -2px;
	left: -2px;
	position: absolute;
	animation: pulsate 1.8s ease-out;
	animation-iteration-count: infinite;
	animation-delay: 1s;
	opacity: 0;
	box-sizing: content-box;
}

@-webkit-keyframes pulsate {
	0% {-webkit-transform: scale(1, 1); opacity: 0.0;}
	25% {opacity: 0.5; }
	50% {-webkit-transform: scale(1.6, 1.6); opacity: 0.0;}
}

/* Minimap */
.mapplic-minimap {
	border: 1px solid rgba(0, 0, 0, 0.1);
	position: absolute;
	margin: 12px;
	bottom: 0;
	left: 0;
	opacity: 0.5;
	overflow: hidden;
	transition: opacity 0.4s;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mapplic-minimap-layer { line-height: 0; }
.mapplic-minimap img { width: 100%; }

.mapplic-minimap-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.mapplic-minimap .mapplic-minimap-active {
	position: absolute;
	opacity: 1;
	top: 0;
	left: 0;
}

.mapplic-minimap-background {
	width: 140px !important;
	-webkit-filter: blur(2px);
}

/* UI Buttons */
.mapplic-button {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	width: 28px;
	height: 28px;
	transition: background-color 0.2s;
}

.mapplic-container > .mapplic-button {
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
	position: absolute;
	margin: 12px;
} 

.mapplic-button:active {
	background-color: #f8f8f8;
}

/* Clear Button */
.mapplic-clear-button {
	background-image: url(/assets/img/mapplic/reset.svg);
	background-size: 16px 16px;
	display: none;
	bottom: 69px;
	right: 0;
}

/* Zoom Buttons */
.mapplic-zoom-buttons {
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
	margin: 12px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.mapplic-zoom-buttons a {
	display: block;
}

.mapplic-zoom-buttons a.mapplic-disabled {
	background-color: #eee;
	cursor: default;
}

a.mapplic-zoomin-button {
	background-image: url(/assets/img/mapplic/plus.svg);
	background-size: 10px 10px;
	border-bottom: 1px solid #eee;
}

a.mapplic-zoomout-button {
	background-image: url(/assets/img/mapplic/minus.svg);
	background-size: 10px 10px;
}

/* Fullscreen */
.mapplic-fullscreen {
	background-color: rgba(248, 248, 248, 0.95);
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100% !important;
	margin: 0 !important;
	max-width: 100% !important;
	max-height: 100% !important;
	z-index: 99980;
}

.mapplic-fullscreen-button {
	background-image: url(/assets/img/mapplic/fullscreen.svg);
	background-size: 14px 14px;
	top: 0;
	left: 0;
}

.mapplic-fullscreen .mapplic-fullscreen-button {
	background-image: url(/assets/img/mapplic/fullscreen-exit.svg);
}

.mapplic-fullscreen .mapplic-container { width: 70%; }
.mapplic-fullscreen .mapplic-sidebar { width: 30%; }

/* Levels */
.mapplic-levels {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
	position: absolute;
	top: 0;
	right: 0;
	margin: 12px;
	overflow: hidden;
}

.mapplic-levels > * {
	display: block;
	box-sizing: border-box;
}

.mapplic-levels-select {
	background-color: #fff;
	border: none;
	border-radius: 0;
	color: #2f3435;
	margin: 0;
	padding: 10px 8px;
	margin-right: 20px;
	font-size: 13px;
	font-weight: 600;
	outline: none;
	-webkit-appearance: none;
}

.mapplic-levels a {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 8px 4px;
	cursor: pointer;
	height: 50%;
	width: 20px;
	position: absolute;
	right: 0;
}

.mapplic-levels a:active {
	background-color: #f8f8f8;
}

.mapplic-levels .mapplic-levels-up {
	background-image: url(/assets/img/mapplic/arrow-up.svg);
}

.mapplic-levels .mapplic-levels-down {
	background-image: url(/assets/img/mapplic/arrow-down.svg);
	bottom: 0;
}

.mapplic-levels a.mapplic-disabled {
	background-color: #eee;
	cursor: default;
}

/* Sidebar */
.mapplic-sidebar {
	width: 30%;
	height: 100%;
	padding: 92px 0 12px 12px;
	position: relative;
	box-sizing: border-box;
	pointer-events: none;
}

.mapplic-sidebar > * {
	pointer-events: auto;
}

.mapplic-container {
	float: right;
}

/* Right sidebar */
.mapplic-sidebar-right .mapplic-container { float: left; }
.mapplic-sidebar-right .mapplic-sidebar {
	float: right;
	padding: 92px 12px 12px 0;
}

/* Search */
.mapplic-filter {
	background-color: #fff;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
	box-sizing: border-box;
	margin: 0;
	overflow: hidden;
	margin-top: -80px;
	padding: 16px 20px;
	position: relative;
	width: 100%;
	z-index: 1;
}

.mapplic-search-input {
	background-image: url(/assets/img/mapplic/magnifier.svg) !important;
	background-size: 14px 14px !important;
	background-repeat: no-repeat !important;
	background-position: 0 0 !important;
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	box-sizing: border-box;
	border-radius: 0;
	font-size: 20px !important;
	font-family: inherit;
	line-height: 20px;
	width: 100%;
	height: 48px !important;
	margin: 0 !important;
	padding: 24px 24px 4px 0 !important;
}

.mapplic-search-input:focus {
	outline: none !important;
}

.mapplic-search-input::placeholder {
	color: #ccc;
	font-weight: 400;
}

.mapplic-search-input::-ms-clear { display: none; width:0; height:0; }

.mapplic-search-clear {
	background-image: url(/assets/img/mapplic/cross.svg);
	background-size: 8px 8px;
	background-repeat: no-repeat;
	background-color: transparent;
	background-position: center;
	box-shadow: none;
	border: none;
	cursor: pointer;
	display: none;
	position: absolute;
	top: 32px;
	right: 8px;
	margin: 2px 0;
	width: 34px;
	height: 34px;
}

/* Search Disabled */
.mapplic-sidebar-nosearch { padding-top: 56px; }
.mapplic-sidebar-nosearch .mapplic-filter {
	margin-top: -44px;
	height: 44px;
}

/* Tags */
.mapplic-filter-tags {
	text-align: right;
	position: absolute;
	top: 14px;
	right: 16px;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
}

.mapplic-tag {
	background-color: #2f3434;
	border-radius: 12px;
	color: #fff;
	cursor: pointer;
	font-size: 11px;
	font-weight: 600;
	margin-left: 4px;
	padding: 1px 6px 2px 8px;
	display: inline-block;
}

.mapplic-tag > span {
	background-image: url(/assets/img/mapplic/cross-light.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 6px 6px;
	display: inline-block;
	margin-left: 6px;
	margin-bottom: 1px;
	width: 6px;
	height: 6px;
	opacity: 0.5;
	transition: opacity 0.2s;
}

.mapplic-tag:hover > span {
	transform: scale(1.2);
	opacity: 1.0;
}

/* Sidebar list */
.mapplic-list-container {
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
	box-sizing: border-box;
	height: auto;
	max-height: 100%;
	overflow-y: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.mapplic-list-container:before {
	display: block;
	content: '';
	background-color: #fff;
	width: 100%;
	height: 10px;
	position: absolute;
	z-index: 100;
}

.mapplic-list {
	list-style: none;
	padding: 0;
	padding-bottom: 10px;
	margin: 0;
	overflow-y: auto;
	height: 100%;
}

.mapplic-list-container ol {
	border-color: #eee;
	list-style: none;
	padding: 0;
	margin: 0;
}

.mapplic-list-expandable {
	background-color: #fff;
	padding: 10px 0;
}

.mapplic-list-container li {
	margin: 0 !important;
}

.mapplic-list-category > a {
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
	color: #2f3434 !important;
	display: block;
	margin: 6px 10px;
	margin-bottom: 0;
	padding: 10px;
	text-decoration: none;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: background-color 0.2s;
}

.mapplic-list-category > a:hover {
	background-color: #f8f8f8;
}

.mapplic-list-category h4 {
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	line-height: 22px;
	margin: 0;
}

.mapplic-list-category h4.mapplic-margin {
	margin-top: 9px;
}

.mapplic-about {
	color: #aaa;
	display: block;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
}

/* Thumbnail */
.mapplic-list-category .mapplic-thumbnail {
	background-color: #aaa;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.mapplic-list-category .mapplic-thumbnail-placeholder {
	font-size: 18px;
	font-weight: bold;
	line-height: 25px;
}

.mapplic-thumbnail {
	border-radius: 0px;
	box-shadow: none !important;
	margin-right: 10px;
	float: left;
	width: 50px;
	height: 50px;
	object-fit: cover;
}

.mapplic-thumbnail-placeholder {
	background-color: #eee;
	box-sizing: border-box;
	color: #fff;
	font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  padding: 8px 4px;
  width: 40px;
  height: 40px;
  text-align: center;
  display:none; /* hide for this project */
}

.mapplic-list-category > a .mapplic-list-count {
	color: #aaa;
	font-size: 12px;
	font-weight: normal;
	margin-left: 4px;
}

/* List location */
.mapplic-list-location {
	border-color: #aaa;
	margin: 0 !important;
	padding: 0 !important;
}

.mapplic-list-location[data-location=init] {
	display: none !important;
}

.mapplic-list-location > a {
	background-color: #fff;
	border-left: 2px solid transparent;
	display: block;
	font-size: 14px;
	padding: 10px 20px 10px 18px;
	text-decoration: none;
	transition: border, background-color 0.1s;
}

.mapplic-list-location > a:after {
	content: '';
	display:block;
	clear:both;
}

.mapplic-list-location > a:hover,
.mapplic-list-location > a:focus,
.mapplic-list-location.mapplic-focus > a {
	background-color: #fafafa;
}

.mapplic-list-location.mapplic-active > a {
	background-color: #fafafa;
	border-color: inherit;
}

.mapplic-list-location h4 {
	color: #2f3435;
	font-size: 14px;
	font-weight: normal;
	line-height: 18px;
	margin: 4px 0 !important;
	padding: 0;
  clear: none;
  text-transform: none;
}

/* Tooltip */
.mapplic-tooltip {
	display: none;
	position: absolute;
	-webkit-transition: margin 0.1s;
	-moz-transition: margin 0.1s;
	transition: margin 0.1s;
	transform-origin: 0 0;
	padding-bottom: 30px;
	pointer-events: none;
}

.mapplic-tooltip:after {
	content: '';
	border-color: #fff transparent transparent transparent !important;
	border-style: solid;
	border-width: 8px 7px 0 7px;
	width: 0;
	height: 0;
	position: absolute;
	bottom: 30px;
	left: 50%;
	margin-bottom: -7px;
	margin-left: -7px;
}

.mapplic-tooltip-wrap {
	background-color: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	max-width: 300px;
	min-width: 120px;
	padding: 16px;
	pointer-events: auto;
}

.mapplic-tooltip-wrap::after {
	content: '';
	clear: both;
	display: table;
}

.mapplic-tooltip img {
	max-width: 100%;
}

.mapplic-tooltip .mapplic-image {
	margin: -16px;
	max-width: none;
}

.mapplic-tooltip-title {
	color: #333;
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	line-height: 20px;
	margin: 0 12px 0 0 !important;
}

.has-image .mapplic-tooltip-title {
	color: #fff;
	margin: -20px 12px 20px 0 !important;
}

.mapplic-hovertip,
.mapplic-hovertip > .mapplic-tooltip-wrap {
	pointer-events: none;
}

.mapplic-hovertip > .mapplic-tooltip-wrap {
	min-width: 20px;
	padding: 6px 14px;
}

.mapplic-hovertip .mapplic-tooltip-title {
	margin: 0 !important;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
}

.mapplic-tooltip-content {
	margin-top: 10px;
	margin-bottom: 6px;
	max-height: 160px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.mapplic-tooltip .mapplic-thumbnail {
	border-radius: 50% !important;
	width: 48px;
	height: 48px;
	margin-right: 12px;
}

.mapplic-tooltip-description,
.mapplic-tooltip p {
	color: #888;
	font-size: 13px;
	line-height: 20px;
	margin: 0;
}

.mapplic-tooltip p {
	margin-top: 0;
	margin-bottom: 6px;
}

.mapplic-tooltip-description p:last-child {
	margin: 0;
}

.mapplic-popup-link {
	background-color: #9babb4;
  border-width: 1px;
  border-color: #6b828f;
  padding: .5rem .75rem;
  color: #fff !important;
  font-family: Oxygen,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Ubuntu,Cantarell,'Fira Sans','Droid Sans',sans-serif;
	font-size: 14px;
	font-weight: 100;
	display: inline-block;
	float: right;
	margin-top: 4px;
  text-decoration: none;
  text-transform: uppercase;
}

.mapplic-popup-link:hover {
	border-color: #4e5f68;
  background-color: #6b828f;
}

.mapplic-tooltip-close {
	background-image: url(/assets/img/mapplic/cross.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 8px 8px;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	border: none;
	cursor: pointer;
	position: absolute;
	right: 6px;
	top: 6px;
	padding: 6px;
	width: 8px;
	height: 8px;
	opacity: 0.5;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.mapplic-tooltip-close:hover {
	opacity: 1.0;
}

/* Lightbox */
.mapplic-lightbox-title {
	color: #333;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 20px;
}

.mapplic-lightbox {
	background-color: #fff;
	position: relative;
	padding: 20px;
	width: auto;
	max-width: 500px;
	margin: 20px auto;
}

.mapplic-lightbox:after {
	content: '';
	display: block;
	clear: both;
}

.mapplic-popup-image {
	outline: none;
}

.mfp-bg { z-index: 99981 !important; }
.mfp-wrap { z-index: 99982 !important; }
.mfp-content { z-index: 99983 !important; }

/* Lightbox animation */
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready { opacity: 0.8; }
.mfp-fade.mfp-bg.mfp-removing { opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content { opacity: 1; }
.mfp-fade.mfp-wrap.mfp-removing .mfp-content { opacity: 0; }

/* Toggle */
.mapplic-toggle {
	display: block;
	cursor: pointer;
	font-size: 14px;
	position: relative;
	min-width: 20px;
	min-height: 20px;
}

.mapplic-toggle > .mapplic-toggle-circle {
	background-color: #aaa;
	border-radius: 50%;
	position: absolute;
	left: 1px;
	top: 1px;
	width: 18px;
	height: 18px;
	transform-origin: center;
	transform: scale(0);
	transition: 0.2s;
}

.mapplic-toggle:before {
	background-color: #fff;
	border: 1px solid #e4e4e4;
	border-radius: 50%;
	box-sizing: content-box;
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
}

.mapplic-toggle > input { display: none; }
.mapplic-toggle > input:checked + span {
	transform: scale(0.7);
}

.mapplic-list-category { position: relative; }
.mapplic-list-category .mapplic-toggle {
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -10px;
	box-sizing: border-box;
}

/* Legend */
.mapplic-legend {
	background-color: rgba(255,255,255, 0.9);
	margin: 12px;
	padding: 0 8px;
	position: absolute;
	left: 0;
	bottom: 0;
}

.mapplic-legend-label {
	display: block;
	font-size: 14px;
	font-weight: normal;
	margin: 6px 0;
	padding-left: 28px;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-ms-user-select: none;
}

.mapplic-legend-key {
	background-color: #aaa;
	border: 2px solid #fff;
	border-radius: 50%;
	box-sizing: content-box;
	display: inline-block;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 8px;
}

/* Reveal */
.mapplic-revealed {
	display: block !important;
}

/* Portrait mode */
.mapplic-portrait .mapplic-container,
.mapplic-portrait .mapplic-sidebar {
	width: 100% !important;
}

.mapplic-portrait .mapplic-sidebar {
	padding: 92px 12px 12px 12px !important;
}

.mapplic-portrait .mapplic-tooltip {
	max-width: 240px;
}

.mapplic-portrait .mapplic-minimap-background {
	width: 100px !important;
}

.mapplic-portrait.mapplic-element {
	height: auto !important;
}

.mapplic-portrait .mapplic-list-container {
	max-height: 600px;
}

/* Retina */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
	.mapplic-pin { background-image: url(/assets/img/mapplic/pin@2x.png); }
	.mapplic-pin.orange { background-image: url(/assets/img/mapplic/pin-orange@2x.png); }
	.mapplic-pin.yellow { background-image: url(/assets/img/mapplic/pin-yellow@2x.png); }
	.mapplic-pin.green { background-image: url(/assets/img/mapplic/pin-green@2x.png); }
	.mapplic-pin.blue { background-image: url(/assets/img/mapplic/pin-blue@2x.png); }
	.mapplic-pin.purple { background-image: url(/assets/img/mapplic/pin-purple@2x.png); }
}

/* Map */
.mapplic-element svg {
	width: 100%;
	height: 100%;
}

.mapplic-element svg a {
	cursor: pointer;
}

.mapplic-clickable:not(g),
g.mapplic-clickable > * {
	cursor: pointer;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.mapplic-map-image *[id^=nopointer] {
	pointer-events: none;
}

[id^=landmarks] .mapplic-clickable {
	cursor: pointer;
}

/* Developer tools */
.mapplic-coordinates {
	background-color: rgba(255, 255, 255, 0.9);
	color: #333;
	position: absolute;
	margin: 10px;
	margin-left: -80px;
	padding: 4px 6px;
	font-size: 14px;
	top: 0;
	left: 50%;
	pointer-events: none;
}

/* SKINS */
/* mapplic-booking */
.mapplic-booking .mapplic-active,
.mapplic-booking .mapplic-active > * {
	fill: #A8D865;
}

.mapplic-booking .unavailable,
.mapplic-booking .unavailable > * {
	fill: #F7B332;
	opacity: 1;
	cursor: default;
}

/* mapplic-dark */
.mapplic-dark .mapplic-tooltip-wrap {	
	background-color: #333;
}

.mapplic-dark .mapplic-tooltip:after {
	border-color: #333 transparent transparent transparent !important;
}

.mapplic-dark .mapplic-tooltip-title {
	color: #fff;
}

.mapplic-dark .mapplic-tooltip-description,
.mapplic-dark .mapplic-tooltip p {
	color: #bbb;
}

.mapplic-dark .mapplic-tooltip-close {
	background-color: rgba(51, 51, 51, 0.5);
	background-image: url(/assets/img/mapplic/cross-light.svg) !important;
}

/* INTERACTIVE ELEMENTS */
/* clickable elements */
.mapplic-clickable:not(g),
g.mapplic-clickable > * {
	opacity: 0.9;
	/*fill: #b7a6bd*/;
}

/* hovered elements */
.mapplic-clickable:not(g):hover,
g.mapplic-clickable:hover > * {
	opacity: 0.8;
}

/* active elements */
.mapplic-active,
a.mapplic-active > path,
g.mapplic-active > * {
  opacity: 1.0 !important;
  fill: #4CAF50;
}

/* Transparent */
.mapplic-transparent .mapplic-clickable:not(g),
.mapplic-transparent g.mapplic-clickable > * {
	opacity: 0.6;
}

.mapplic-transparent .mapplic-clickable:not(g):hover,
.mapplic-transparent g.mapplic-clickable:hover > * {
	opacity: 0.8;
}

.mapplic-transparent .mapplic-active,
.mapplic-transparent a.mapplic-active > path,
.mapplic-transparent g.mapplic-active > * {
	opacity: 1.0 !important;
}

/* CUSTOM STYLES */
.mapplic-image,
.mapplic-tooltip { max-width: 300px !important; } /* tooltip width */
.mapplic-tooltip-content { max-height: 160px; } /* tooltip height*/

/* example custom pin */
.mapplic-pin.my-new-pin { /* replace 'my-new-pin' with the name of your pin */
	background-image: url(/assets/img/mapplic/my-new-pin.png); /* define the path to image file */
	background-size: 20px 30px;
	width: 20px;
	height: 30px;
	margin-left: -10px; /* negative margins are used for */
	margin-top: -15px; /* defining the pin's origin */
}
//
// Type Defaults
//

// Use these to include the font into classes
//@mixin fontname  { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }

// Font aintaliasing. Includes webkit and firefox version to be included in a future version */
@mixin fontsmoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body { font-size: 16px; }

// Default body styles
body {
  @apply text-grey-dark bg-cream font-addon;
  // font-family: 'Oxygen', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
}

// Override outline from normalize, we don't like it
a {
  @apply text-orange;
  transition: color 0.2s cubic-bezier(.21, 0, .36, 1),
              background-color 0.2s cubic-bezier(.21, 0, .36, 1);
}
a:hover { @apply text-orange-darker; }
a:focus { outline: none; }


//
// Headers
//

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  @apply font-addon text-brown font-normal leading-none mb-4 uppercase;
}

h1 { @apply text-5xl; }
h2 { @apply text-4xl; }
h3 { @apply text-3xl; }
h4 { @apply text-2xl; }
h5 { @apply text-xl; }
h6 { @apply text-lg; }

//
// Paragraphs
//

p {
  @apply font-sans;
  line-height: 1.6;
  margin: 0 0 em-calc(20);
  text-rendering: optimizeLegibility;
}

//
// Lists
//

ul,
ol {
  @apply font-sans;
  line-height: 1.6;
  padding-left: 1em;
}

// Reset tailwind's default of unstyling lists
ul {
  list-style-type: disc;
  padding-left: theme('padding.4');
}

ol {
  list-style-type: decimal;
  padding-left: theme('padding.4');
}


//
// Blockquotes
// blockquote>div+footer

blockquote {
  @apply leading-normal mb-4;

  div {
    quotes: "“" "”" "‘" "’";

    &::before { content: open-quote; }
    &::after  { content: close-quote; }
  }

  p { display: inline; }
}

blockquote footer {
  @apply mt-4 font-normal italic text-right;
}


//
// Misc Global Styles
//

hr {
  height: 1px;
  color: #ccc;
  background: #ccc;
  font-size: 0;
  border: 0;
}

//
// Lazysizes 
// Adds fade in effect for for lazysizes images
// ----------------------------------------------

// help lazysizes plugin calculate image sizes
img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

.lazysizes-display-clone {
  height: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.blur-up {
  filter: blur(15px);
  transition: filter 1s, -webkit-filter 1s;
}

.blur-up.lazyloaded {
  filter: blur(0);
}
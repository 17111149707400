// Hero Block
/////////////////////////////////////////////////////////////////

.hero {
  @apply relative bg-black bg-cover bg-center text-center text-white mx-auto;
  /*min-height: 50vw;*/
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$medium-up} {
    height: 60vw;

  }

  @media screen and (max-width: 769px) {
    min-height: 400px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 0) 95%
    );
  }
}

.hero-short {
  @media #{$medium-up} {
    height: 40vw;
  }
}

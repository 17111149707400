// Forms for Contact and Leasing
/////////////////////////////////////////////////////////////////


.forms__main {

  input,
  textarea {
    @apply p-2 pl-0 mb-6 font-addon text-blue-darker bg-cream;
    border: none;
    border-bottom: 4px solid;
    border-color: theme('colors.blue');
    border-radius: 0;

    &:focus {
      border-color: theme('colors.blue-darker');
    }
  }

  label {
    @apply block mb-2 uppercase;
  }

  #favoriteHoney {
    display: none;
  }
}

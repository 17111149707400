.embed {
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%;

  > div,
  iframe,
  object,
  embed {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }
} 

.embed-1x1 {
  @extend .embed;
  padding-bottom: 100%;
}

.embed-4x3 {
  @extend .embed;
  padding-bottom: 75%;
}
// Make sure the charset is set appropriately
@charset "UTF-8";

// Helpers
@import "helpers/em_calc";
@import "helpers/rem_calc";
@import "helpers/poly_fluid_sizing";
@import "helpers/clearfix";
@import "settings/variables";

/**
* This injects Tailwind's base styles, which is a combination of
* Normalize.css and some additional base styles.
*/
@tailwind base;

/**
* This injects any component classes registered by plugins.
*
* If using `postcss-import`, use this import instead:
*
* @import "tailwindcss/components";
*/
@tailwind components;

// Vendor files and plugins
@import "vendor/lazysizes";
@import "vendor/mapplic";
@import "vendor/magnific-popup";

// Base - styles

@import "components/base/base";
@import "components/base/typography";
@import "components/base/buttons";
@import "components/base/print";

// Blocks - general building block styles

@import "components/blocks/hero";
@import "components/blocks/merchants";
@import "components/blocks/forms";
@import "components/blocks/navigation";

// @import "components/base/icons";

// Pages

@import "components/pages/extra_globals.scss";
@import "components/pages/careers.scss";

/**
* This injects all of Tailwind's utility classes, generated based on your
* config file.
*/
@tailwind utilities;

/**
* Here you would add any custom utilities you need that don't come out of the
* box with Tailwind.
*/

@import "utilities/embed";
@import "~animate.css/animate.css";
@import "~@glidejs/glide/src/assets/sass/glide.core";

// Cloak Vue items
[v-cloak] { display: none !important }

/*
 * only suppress the focus ring once what-input has successfully started
 * suppress focus ring on form controls for mouse users
 */
[data-whatintent='mouse'] *:focus {
  outline: none;
}

.container--tight {
  @apply max-w-lg mx-auto w-5/6;
}
